import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, WarningIcon, Tabs } from './components';
import { getEmailTemplatesCurl, getEmailTemplatesJava, getEmailTemplatesJavascript, getEmailTemplatesNode, getEmailTemplatesPerl, getEmailTemplatesPython, getEmailTemplatesPhp, getEmailTemplatesRuby, getEmailTemplatesGo, getEmailTemplatesC, getEmailTemplatesVB, getEmailTemplatesGroovy, getEmailTemplatesObjectiveC, getEmailTemplatesSwift, updateEmailTemplatesRaw, updateEmailTemplatesCurl, updateEmailTemplatesJava, updateEmailTemplatesJavascript, updateEmailTemplatesNode, updateEmailTemplatesPerl, updateEmailTemplatesPython, updateEmailTemplatesPhp, updateEmailTemplatesRuby, updateEmailTemplatesGo, updateEmailTemplatesC, updateEmailTemplatesVB, updateEmailTemplatesGroovy, updateEmailTemplatesObjectiveC, updateEmailTemplatesSwift } from 'requestExamples/email-templates';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/email-template/{account_name}/{email_template_name}',
  subtitle: 'Retrieve an email template',
  link: '#retrieve-an-email-template'
}, {
  param: 'POST',
  value: '/nodes/email-template/{account_name}/{email_template_name}',
  subtitle: 'Update an email template',
  link: '#update-an-email-template'
}];
export const retrieveEmailTemplate = [{
  param: 'GET',
  value: '/nodes/email-template/{account_name}/{email_template_name}'
}];
export const updateTemplate = [{
  param: 'POST',
  value: '/nodes/email-template/{account_name}/{email_template_name}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  retrieveEmailTemplate,
  updateTemplate,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "email-templates"
    }}>{`Email templates`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault allows users to retrieve and modify pre-defined email templates. You can't create new templates.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			All the endpoints related to email templates are case sensitive, so be careful when doing the request
			because if you don't specify the casing properly, you will get an error object as a result of the request.
		</p>
	</div>
	<Table title="Endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <br />

    <h2 {...{
      "id": "retrieve-an-email-template"
    }}>{`Retrieve an email template`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault allows users to retrieve and modify pre-defined email templates. Use the path
			/nodes/email-template for email template management.
			<br />
			<br />
		</p>
	</div>
	<Table title="Retrieve an email template" rows={retrieveEmailTemplate} mdxType="Table" />
    </Row>
    <br />
    <br />
    <div>
	Default email templates present in each account may vary so, in case you want to check the current email templates,
	don't specify the "email_template_name" and check for the direct child of the request using the "children" property
	like:
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/email-template/SmartVault Account?children=1
`}</code></pre>
    <div>
	Also, if you want to know the usage of each email template, use the "eprop" query param and check the "description"
	field.
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/email-template/SmartVault Account?children=1&eprop=true
`}</code></pre>
    <div>
	Check the expected result from this request in the "Response" section below and get more info about these optional
	query params{' '}
	<a target="_blank" href="/api#requests-query-parameters">
		here
	</a>
	.
    </div>
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr />
    <Attributes items={[{
      name: 'account_name',
      type: 'string',
      description: "The user's account name."
    }, {
      name: 'email_template_name',
      type: 'string (optional)',
      description: 'Name of email template you want to retrieve information from.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getEmailTemplatesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getEmailTemplatesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getEmailTemplatesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getEmailTemplatesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getEmailTemplatesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getEmailTemplatesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getEmailTemplatesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getEmailTemplatesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getEmailTemplatesGo
    }, {
      tabTitle: 'C#',
      tabInfo: getEmailTemplatesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getEmailTemplatesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getEmailTemplatesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getEmailTemplatesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getEmailTemplatesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Example of a partial response from a request to get all the current email templates for an account.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault Account",
        "createdOn": "2020-07-08T15:31:01-05:00",
        "modifiedOn": "2020-07-08T15:31:01-05:00",
        "uri": "/nodes/email-template/SmartVault Account",
        "your_permissions": 64,
        "total_children": 12,
        "children": [
            {
                "name": "FirmUserAddedNotification",
                "createdOn": "2020-07-08T15:31:01-05:00",
                "modifiedOn": "2020-07-08T15:31:01-05:00",
                "uri": "/nodes/email-template/SmartVault Account/FirmUserAddedNotification",
                "your_permissions": 81,
                "emailTemplateExProperties": {
                    "subject": "%FROMNAME% has shared %SUBJECTTEXT%",
                    "body": "%HEADER%%BEGINSECTION%<p>\\n\\tTo provide you with a safe and secure way to share files with our firm, we have partnered with SmartVault, an award-winning online document storage and secure file sharing solution, to create a <strong>Client Portal</strong>.\\n</p>\\n<p>\\n\\tFor your secure Client Portal, our firm has created %TEXT2%\\n</p>\\n<p>\\n\\t%BEGINITEM%%Message%%ENDITEM%\\n</p>\\n<p>Enjoy access to your files any time through the Client Portal. Your data is always backed up and exchanging files in the client portal is secure, unlike email attachments.\\n</p>\\n<p>\\n\\tThe SmartVault Client Portal is also very easy to use. If you can click a link in an email, you can use SmartVault to access your files. It's really that easy. For a quick tour of the Client Portal, click here: <a href=\\"https://support.smartvault.com/01_New_Users/Getting_Started_with_SmartVault_for_Tax_Clients\\">Client Portal Orientation</a>\\n</p>\\n<p>\\n\\tPlease click the <strong>'Activate'</strong> button to finish your account activation.\\n</p>\\n<div style=\\"width:500px; text-align: center\\">\\n\\t \\n\\t<div style=\\"margin: auto\\">\\n\\t\\t <a href=\\"%URL%\\"> <img alt=\\"SmartVault Icon\\" src=\\"%SmartVaultLogoIconUrl%\\" style=\\"border: 0;\\" /> </a> \\n\\t</div>\\n\\t \\n\\t<div style=\\"margin: auto\\">\\n\\t\\t <a href=\\"%URL%\\"> <strong>Activate</strong> </a> \\n\\t</div>\\n</div>\\n<p>\\n\\tWe are always here to help, so feel free to contact us with questions or feedback.\\n</p>%ENDSECTION%%FOOTER%",
                    "properties": [
                        {
                            "key": "DeliverEmail",
                            "description": "The receipients email",
                            "example": "MailReview_pp@smartvault.com"
                        },
                        {
                            "key": "UserName",
                            "description": "",
                            "example": "Thomas Jefferson"
                        },
                        {
                            "key": "ObjectId",
                            "description": "",
                            "example": "Vault:3tAXrELThUCMFlQyclM94Q"
                        },
                        {
                            "key": "Message",
                            "description": "",
                            "example": "Personal Message"
                        },
                        {
                            "key": "Url",
                            "description": "",
                            "example": "https://test.smartvault.com"
                        },
                        {
                            "key": "Text",
                            "description": "",
                            "example": ""
                        },
                        {
                            "key": "Text2",
                            "description": "",
                            "example": ""
                        },
                        {
                            "key": "FromName",
                            "description": "",
                            "example": "George Washington"
                        },
                        {
                            "key": "SubjectText",
                            "description": "",
                            "example": ""
                        },
                        {
                            "key": "AccountId",
                            "description": "Textual account identifier",
                            "example": "3tAXrELThUCMFlQyclM94Q"
                        },
                        {
                            "key": "Portal",
                            "description": "Link to the portal",
                            "example": "https://my.smartvault.com"
                        },
                        {
                            "key": "EmailClosingText",
                            "description": "Closing text for email",
                            "example": "SmartVault"
                        },
                        {
                            "key": "DisclosureStatement",
                            "description": "Disclosure statement text for email",
                            "example": "SmartVault"
                        },
                        {
                            "key": "EmailHeaderLogoUrl",
                            "description": "Link to email logo",
                            "example": "https://my.smartvault.com/secure/EmailHeader.ashx"
                        },
                        {
                            "key": "CurrentYear",
                            "description": "Current copyright year",
                            "example": "2020"
                        },
                        {
                            "key": "SmartVaultLogoIconUrl",
                            "description": "Link to SmartVault Logo Icon",
                            "example": "https://my.smartvault.com/artwork/server/Graphics/SV-keyhole-2018-TEAL-4x.png"
                        }
                    ],
                    "display_name": "User Activation",
                    "description": "This email template is used when an employee or guest is created (by sharing) and needs to activate their user id."
                }
            },
            {
                "name": "NonIndividualTaxTemplate",
                "createdOn": "2020-07-08T15:31:01-05:00",
                "modifiedOn": "2020-07-08T15:31:01-05:00",
                "uri": "/nodes/email-template/SmartVault Account/NonIndividualTaxTemplate",
                "your_permissions": 81,
                "emailTemplateExProperties": {
                    "subject": "Your %CurrentTaxYear% %TaxType% Tax Return is Complete",
                    "body": "%HEADER%%BEGINSECTION%Dear %ClientSalutation%,<p>Your %TaxType% income tax return has been completed. For your convenience, we have uploaded a client copy and a government copy of your return. The client return is your copy to access at any time in your secure client portal.</p><p>The SmartVault Client Portal is very easy to use. If you can click a link in an email, you can use SmartVault to access your files. It's really that easy. For a quick tour of the Client Portal, click here: <a href=\\"http://support.smartvault.com/01_New_Users/z_03-Step-3-For-Guests\\">Client Portal Orientation</a></p><p>For future reference, your tax returns can be found in this folder:</p><p><a href=\\"%ClientTaxReturnsUrl%\\">%ClientTaxReturnsName%</a></p><p>The client copy can be downloaded by this link:</p><p><a href=\\"%TaxReturnClientCopyUrl%\\">%TaxReturnClientCopyName%</a></p><p>The government copy can be downloaded by this link:</p><p><a href=\\"%TaxReturnGovernmentCopyUrl%\\">%TaxReturnGovernmentCopyName%</a></p><p>%PreparerName%</p>%ENDSECTION%%FOOTER%",
                    "properties": [
                        {
                            "key": "DeliverEmail",
                            "description": "The receipients email",
                            "example": "MailReview_pp@smartvault.com"
                        },
                        {
                            "key": "TaxType",
                            "description": "The type of tax return",
                            "example": "Corporation"
                        },
                        {
                            "key": "TaxReturnGovernmentCopyUrl",
                            "description": "The link to the government copy tax return",
                            "example": "https://my.smartvault.com"
                        },
                        {
                            "key": "TaxReturnGovernmentCopyName",
                            "description": "The name of the government copy tax return",
                            "example": "2013_01SAMPLE_Sale and A Bulk_GovernmentCopy_Individual.pdf"
                        },
                        {
                            "key": "TaxReturnClientCopyUrl",
                            "description": "The link to the client copy tax return",
                            "example": "https://my.smartvault.com"
                        },
                        {
                            "key": "TaxReturnClientCopyName",
                            "description": "The name of the client copy of the tax return",
                            "example": "2013_01SAMPLE_Sale and A Bulk_ClientCopy_Individual.pdf"
                        },
                        {
                            "key": "ClientTaxReturnsUrl",
                            "description": "The link to the folder where tax returns are stored",
                            "example": "https://my.smartvault.com"
                        },
                        {
                            "key": "ClientTaxReturnsName",
                            "description": "The name of the folder where tax returns are stored",
                            "example": "Tax Returns"
                        },
                        {
                            "key": "Upload8879CFolderUrl",
                            "description": "The link to the folder where the 8879 form should be uploaded",
                            "example": "https://my.smartvault.com"
                        },
                        {
                            "key": "Upload8879CFolderName",
                            "description": "The name of the folder where the 8879 form should be uploaded",
                            "example": "Source Documents"
                        },
                        {
                            "key": "AccountId",
                            "description": "Textual account identifier",
                            "example": "3tAXrELThUCMFlQyclM94Q"
                        },
                        {
                            "key": "ClientSalutation",
                            "description": "The name of the client to use at the top of emails",
                            "example": ""
                        },
                        {
                            "key": "PreparerName",
                            "description": "The full name of the preparer of a tax return",
                            "example": ""
                        },
                        {
                            "key": "CurrentTaxYear",
                            "description": "The current tax year",
                            "example": ""
                        },
                        {
                            "key": "Portal",
                            "description": "Link to the portal",
                            "example": "https://my.smartvault.com"
                        },
                        {
                            "key": "EmailClosingText",
                            "description": "Closing text for email",
                            "example": "SmartVault"
                        },
                        {
                            "key": "DisclosureStatement",
                            "description": "Disclosure statement text for email",
                            "example": "SmartVault"
                        },
                        {
                            "key": "EmailHeaderLogoUrl",
                            "description": "Link to email logo",
                            "example": "https://my.smartvault.com/secure/EmailHeader.ashx"
                        },
                        {
                            "key": "CurrentYear",
                            "description": "Current copyright year",
                            "example": "2020"
                        },
                        {
                            "key": "SmartVaultLogoIconUrl",
                            "description": "Link to SmartVault Logo Icon",
                            "example": "https://my.smartvault.com/artwork/server/Graphics/SV-keyhole-2018-TEAL-4x.png"
                        }
                    ],
                    "display_name": "Tax Return - Non-Individual",
                    "description": "This email template is used when sending tax returns to your non-individual clients."
                }
            },
            {
                ...
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "/api/terminology#node-response"
      }}>{`node response object`}</a>{` pointing to the email template retrieved.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Return of a https://rest.smartvault.com/nodes/email-template/{account_name}/FirmUserAddedNotification request
{
    "error": {
        "success": true
    },
    "message": {
        "name": "FirmUserAddedNotification",
        "createdOn": "2020-07-08T15:38:19-05:00",
        "modifiedOn": "2020-07-08T15:38:19-05:00",
        "uri": "/nodes/email-template/{account_name}/FirmUserAddedNotification",
        "your_permissions": 81
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the template specified in the request path doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "a2667276-0acc-482b-bb59-adcaaf61f26a",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "update-an-email-template"
    }}>{`Update an email template`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault allows users to retrieve and modify pre-defined email templates. Use the path
			/nodes/email-template for email template management.
		</p>
	</div>
	<Table title="Update an email template" rows={updateTemplate} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr />
    <Attributes items={[{
      name: 'update',
      type: 'UpdateEmailTemplate (optional)',
      description: 'Specify any changes you would like to make to the subject, body, display name, or description of the email.'
    }, {
      name: 'revert_to_default',
      type: 'boolean (optional)',
      description: 'Setting this to true will reset the email template values, even if you specify new values for the "update" object while doing the request.'
    }]} mdxType="Attributes" />
    <br />
    <h3 {...{
      "id": "the-updateemailtemplate-object"
    }}>{`The UpdateEmailTemplate object`}</h3>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Use the update container to change the properties associated with a template (API name for the template, label for the template, who the template applies to, etc).`}</p>
    <Expander title="UpdateTemplateRequest" mdxType="Expander">
	<Attributes items={[{
        name: 'update_subject',
        type: 'boolean (optional)',
        description: 'Indicates if the subject should be updated.'
      }, {
        name: 'subject',
        type: 'string (optional)',
        description: 'New value for the subject of the email (update_subject must be set to true if this field is being modified).'
      }, {
        name: 'update_body',
        type: 'boolean (optional)',
        description: 'Indicates if the body should be updated.'
      }, {
        name: 'body',
        type: 'string (optional)',
        description: 'New value for the body of the email (update_body must be set to true if this field is being modified).'
      }, {
        name: 'update_display_name',
        type: 'boolean (optional)',
        description: 'Indicates if the display name should be updated.'
      }, {
        name: 'display_name',
        type: 'string (optional)',
        description: 'New value for how the user name will be displayed in the email (update_display_name must set to true if this field is being modified).'
      }, {
        name: 'update_description',
        type: 'boolean (optional)',
        description: 'Indicates if the description should be updated.'
      }, {
        name: 'description',
        type: 'string (optional)',
        description: 'New value for the description of the email template (update_description must set to true if this field is being modified).'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: updateEmailTemplatesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: updateEmailTemplatesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: updateEmailTemplatesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: updateEmailTemplatesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: updateEmailTemplatesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: updateEmailTemplatesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: updateEmailTemplatesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: updateEmailTemplatesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: updateEmailTemplatesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: updateEmailTemplatesGo
    }, {
      tabTitle: 'C#',
      tabInfo: updateEmailTemplatesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: updateEmailTemplatesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: updateEmailTemplatesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: updateEmailTemplatesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: updateEmailTemplatesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "/api/terminology#node-response"
      }}>{`node response object`}</a>{` pointing to the email template retrieved.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "FirmUserAddedNotification",
        "createdOn": "2020-07-08T16:23:06-05:00",
        "modifiedOn": "2020-07-08T16:23:06-05:00",
        "uri": "/nodes/email-template/SmartVault Account/FirmUserAddedNotification",
        "your_permissions": 81
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the email template trying to be modified doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "506c9572-bf49-4147-b3fe-dac45dae5dd9",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      